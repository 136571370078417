.root-div {
  /* display: flex; */
  /* position: relative; */
  /* width: 1900px; */
  height: 100vh;
  /* align-items: flex-start; */
  background-color: #fbfdff;
  overflow: scroll;
}

.slider_images {
  object-fit: contain;
}

/* .frame19 { */
/* display: flex; */
/* position: absolute; */
/* left: 228px; */
/* top: calc(45% - 0.4281px); */
/* width: min-content; */
/* height: min-content; */
/* flex-direction: column; */
/* transform: translateY(-50%); */
/* } */
.frame18 {
  width: 80%;
  margin: 0 auto;
}

/* .frame5 {
  display: flex;
  width: min-content;
  height: min-content;
  flex-direction: column;
  align-items: center;
  gap: 20px;
} */
.welcomeToAppsManager {
  /* display: flex; */
  color: #232526;
  font-size: 40px;
  line-height: 60px;
  font-weight: bold;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  /* width: min-content; */
  /* height: min-content; */
  text-align: center;
  /* align-items: center; */
  white-space: wrap;
  /* flex-direction: column; */
}

.ifYouWantToSurviveThenGoWithYo {
  /* display: flex; */
  color: #232526;
  font-size: 22px;
  line-height: 33px;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  letter-spacing: 0.01em;
  /* width: 641px; */
  /* height: min-content; */
  text-align: center;
  /* align-items: center; */
  /* flex-direction: column; */
}

.left-side {
  position: relative;
}

.frame20 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -5%;
  left: 47%;
  transform: translate(-50%);
}

.frame6 {
  display: flex;
  width: min-content;
  height: min-content;
  align-items: center;
  gap: 10px;
}

._30767091 {
  width: 27px;
  height: 27px;
}

.icon54 {
  width: 100%;
  height: 100%;
}

.privacyPolicy {
  display: flex;
  color: #232526;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}

.frame7 {
  display: flex;
  width: min-content;
  height: min-content;
  align-items: center;
  gap: 10px;
}

._384263620011 {
  width: 27px;
  height: 27px;
}

.icon55 {
  width: 100%;
  height: 100%;
}

.termsCondition {
  display: flex;
  color: #232526;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}

.right-side {
  position: relative;
}

.frame8 {
  display: flex;
  margin: 0 auto;
  width: 75%;
  position: absolute;
  bottom: -5%;
  left: 50%;
  transform: translate(-50%);
  /* left: calc(53% - -390.5px); */
  /* top: 859px; */
  /* width: min-content; */
  /* height: min-content; */
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
  /* transform: trans/lateX(-50%); */
}

.copyright {
  display: flex;
  color: #a5a5a5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}

.vector {
  width: 16px;
  height: 16px;
  overflow: visible;
  position: relative;
}

.icon56 {
  width: 100%;
  height: 100%;
  position: absolute;
}

._2023AppsManagerAllRightsReserv {
  /* display: flex; */
  color: #a5a5a5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: min-content;
  height: min-content;
  /* text-align: center; */
  /* align-items: center; */
  white-space: nowrap;
  /* flex-direction: column;
   */
  display: flex;
  flex-wrap: wrap;
}

.frame17 {
  display: flex;
  margin: 0 auto;
  padding-top: 40px;
  /* position: absolute; */
  /* left: 1192px; */
  /* top: calc(38% - -0.1422px); */
  /* width: min-content; */
  /* height: min-content; */
  flex-direction: column;
  /* place-content: center; */
  align-items: center;
  gap: 50px;
  /* transform: translateY(-50%); */
}

.frame16 {
  display: flex;
  width: min-content;
  height: min-content;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.frame2 {
  display: flex;
  width: min-content;
  height: min-content;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 17.4328px;
}

.frame {
  width: 76.2687px;
  height: 97.8516px;
}

.icon57 {
  width: 100%;
  height: 100%;
}

.appsManager {
  display: flex;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}

.labelWrapper {
  display: flex;
  font-size: 26.1493px;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  flex-shrink: 1;
}

.label {
  color: #000;
  font-size: 26.1493px;
  font-weight: 500;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
}

.label2 {
  color: #0643f2;
  font-size: 26.1493px;
  font-weight: bold;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
}

.aSatisfiedSolutionIsAKeyToEver {
  display: flex;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}

.textBlock {
  color: #232526;
  font-size: 22px;
  line-height: 33px;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
}

.textBlock2 {
  margin-top: 0;
  color: #232526;
  font-size: 22px;
  line-height: 33px;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
}

@media screen and (max-width: 425px) {
  #form form {
    width: 90%;
  }
}

@media screen and (min-width: 1440px) {
  #form form {
    width: 60%;
  }
}

@media screen and (min-width: 1350px) {
  .frame19 {
    padding-top: 40px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1308px) {
  .frame11 {
    margin-top: 60px;
  }
}

.css-f2sysg {
  height: 400px !important;
}

#form form {
  width: 70%;
}

.emailAddress {
  display: flex;
  color: #000;
  font-size: 22px;
  line-height: 24px;
  font-weight: 500;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}

.frame9 {
  display: flex;
  width: 100%;
  height: 56px;
  align-items: center;
  gap: 10px;
  /* padding: 10px 0 10px 10px; */
  border-radius: 5px;
  background-color: #fff;
  /* overflow: hidden; */
  /* box-shadow: 0px 0px 2px 0px #6c89fb; */
}

.mailLine {
  width: 24px;
  height: 24px;
}

.icon58 {
  width: 100%;
  height: 100%;
}

.emailAddress2 {
  display: flex;
  color: #a5a5a5;
  font-size: 18px;
  line-height: 24px;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}

.password {
  /* display: flex; */
  color: #000;
  font-size: 22px;
  line-height: 24px;
  font-weight: 500;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}

.frame10 {
  display: flex;
  width: 100%;
  height: 56px;
  align-items: center;
  gap: 10px;
  /* padding: 10px 0 10px 10px; */
  border-radius: 5px;
  background-color: #fff;
  /* box-shadow: 0px 0px 2px 0px #6c89fb; */
}

.lockLine {
  width: 24px;
  height: 24px;
}

.icon59 {
  width: 100%;
  height: 100%;
}

.password2 {
  display: flex;
  color: #a5a5a5;
  font-size: 18px;
  line-height: 24px;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}

.frame11 {
  /* display: flex; */
  /* width: 464px; */

  place-content: center;
  align-items: center;

  border-radius: 5px;
  /* background-color: #0643f2; */
}

.login {
  color: #fff;
  font-size: 22px;

  font-weight: bold;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  height: min-content;
}

.btn-lg {
  --bs-btn-padding-x: 13rem !important;
}

.error {
  margin-bottom: auto;
  text-align: left;
  font-size: 16px;
  color: red;
  text-transform: capitalize;
}

.remember {
  height: 35px;
  width: 20px;
}