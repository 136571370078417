.root-userVisit-div {
  display: flex;
  flex-wrap: wrap;
  top: 325px;
  padding-top: 25px;
}
.stats {
  position: relative;
  width: 25%;
  height: 136px;
}
.statsCard1 {
  border-radius: 10px;
  background-color: #f34a73;
  padding: 10px;
  position: relative;
  height: 100%;
}
.todaySTotalUserVisit {
  color: #fbfdff;
  font-size: 14px;
  font-family: Barlow, system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  text-transform: capitalize;
  white-space: nowrap;
  flex-direction: column;
}
.bottom {
  position: absolute;
  bottom: 0;
  padding-bottom: 15px;
}
.price {
  padding-bottom: 5px;
}
._12426 {
  color: #fbfdff;
  font-size: 21px;
  line-height: 31.5px;
  font-weight: bold;
  font-family: Barlow, system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.growth {
  display: flex;
  width: min-content;
  height: min-content;
  place-content: flex-end;
  align-items: center;
  gap: 5px;
  padding: 0 5px;
  border-radius: 2px;
  background-color: #ffffffb2;
  border-radius: 12px;
}
._36 {
  font-size: 14px;
  line-height: 19.5px;
  font-weight: 600;
  font-family: Barlow, system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  width: min-content;
  height: min-content;
  text-align: end;
  align-items: end;
  white-space: nowrap;
  flex-direction: column;
}
.iconOutlineArrowUp {
  width: 18px;
  height: 22px;
}
.icon {
  width: 100%;
  height: 100%;
}
.group4149 {
  position: absolute;
  right: -70px;
  top: -5%;
  width: 50%;
  overflow: hidden;
}
.icon2 {
  width: 100%;
  height: 100%;
}
.stats2 {
  position: relative;
  width: 25%;
  height: 136px;
}
.statsCard12 {
  outline: solid 1px #e4e4e7;
  outline-offset: -1px;
  border-radius: 10px;
  background-color: #ff9941;
  padding: 10px;
  position: relative;
  height: 100%;
}
.yesterdayTotalUserVisit {
  color: #fff;
  font-size: 14px;

  font-family: Barlow, system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  text-transform: capitalize;
}
.bottom2 {
  position: absolute;

  bottom: 0;
  padding-bottom: 15px;
}
.price2 {
  padding-bottom: 5px;
}
._124262 {
  color: #fff;
  font-size: 21px;
  line-height: 31.5px;
  font-weight: bold;
  font-family: Barlow, system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}

._362 {
  font-size: 14px;
  line-height: 19.5px;
  font-weight: 600;
  font-family: Barlow, system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  width: min-content;
  height: min-content;
  text-align: end;
  align-items: end;
  white-space: nowrap;
  flex-direction: column;
}

.icon3 {
  width: 82%;
  height: 82%;
}
.group4150 {
  z-index: 0;
  position: absolute;
  right: -70px;
  top: -5%;
  width: 50%;
  overflow: hidden;
}
.icon4 {
  width: 100%;
  height: 100%;
}
.stats3 {
  position: relative;
  width: 25%;
  height: 136px;
}
.statsCard13 {
  outline: solid 1px #e4e4e7;
  outline-offset: -1px;
  border-radius: 10px;
  background-color: #322b7d;
  padding: 10px;
  height: 100%;
  position: relative;
}
.last7DaysUserVisit {
  color: #fff;
  font-size: 14px;

  font-family: Barlow, system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  text-transform: capitalize;
}
.bottom3 {
  position: absolute;

  bottom: 0;
  padding-bottom: 10px;
}
.price3 {
  padding-bottom: 5px;
}
._124263 {
  color: #fff;
  font-size: 21px;
  line-height: 31.5px;
  font-weight: bold;
  font-family: Barlow, system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}

._363 {
  font-size: 13px;
  line-height: 19.5px;
  font-weight: 600;
  font-family: Barlow, system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  width: min-content;
  height: min-content;
  text-align: end;
  align-items: end;
  white-space: nowrap;
  flex-direction: column;
}

.icon5 {
  width: 82%;
  height: 82%;
}
.group4151 {
  z-index: 0;
  position: absolute;
  right: -70px;
  top: -5%;
  width: 50%;
  overflow: hidden;
}
.icon6 {
  width: 100%;
  height: 100%;
}
.stats4 {
  position: relative;
  height: 136px;
}
.statsCard14 {
  padding: 10px;
  outline: solid 1px #e4e4e7;
  outline-offset: -1px;
  border-radius: 10px;
  background-color: #7d2b6c;
  position: relative;
  height: 100%;
}
.last30DaysTotalUserVisit {
  color: #fff;
  font-size: 14px;

  font-family: Barlow, system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  text-transform: capitalize;

  width: min-content;
  height: min-content;
  white-space: nowrap;
}
.bottom4 {
  display: flex;
  position: absolute;

  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  bottom: 0;
  padding-bottom: 15px;
}
.price4 {
  padding-bottom: 5px;
}
._124264 {
  color: #fff;
  font-size: 21px;
  line-height: 31.5px;
  font-weight: bold;
  font-family: Barlow, system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}

._364 {
  font-size: 13px;
  line-height: 19.5px;
  font-weight: 600;
  font-family: Barlow, system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  width: min-content;
  height: min-content;
  text-align: end;
  align-items: end;
  white-space: nowrap;
  flex-direction: column;
}

.icon7 {
  width: 82%;
  height: 82%;
}
.group4152 {
  z-index: 0;
  position: absolute;
  right: -70px;
  top: -5%;
  width: 50%;
  overflow: hidden;
}
.icon8 {
  width: 100%;
  height: 100%;
}

@media (min-width: 1024px) {
  .stats {
    max-width: 10%;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 991px) and (max-width: 1024px) {
  .spacing {
    margin-bottom: 25.5px;
  }
}
