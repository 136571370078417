@media only screen and (max-width: 1200px) {
    .view_detail .app_border {
        border: none;
    }
}

@media only screen and (max-width: 1100px) {
    .container {
        max-width: 100% !important;
    }

    .card-header {
        display: block !important;
    }

    .grid_item {
        grid-template-columns: 1fr 1fr 1fr !important;
    }

    .grid_monetize {
        grid-template-columns: 1fr 1fr !important;
    }

    .list_item {
        grid-template-columns: 1fr 1fr 1fr !important;
    }

    .grid_console {
        grid-template-columns: 1fr 1fr 1fr !important;
    }
}

@media only screen and (max-width: 991px) {
    .dash_block {
        padding-left: 20px !important;
    }
}

@media only screen and (max-width: 500px) {
    .grid_item {
        grid-template-columns: 1fr 1fr !important;
    }

    .block_list {
        grid-template-columns: 1fr 1fr !important;
    }

    .category_grid {
        grid-template-columns: 1fr 1fr !important;
    }

    .grid_console {
        grid-template-columns: 1fr 1fr !important;
    }
}