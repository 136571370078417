@import "rsuite/dist/rsuite.css";

.view_detail {
    .app_view_detail {
        display: flex;
    }

    .app_detail_image {
        height: 110px;
        width: 110px;
        border-radius: 5px;

    }

    .app_border {
        border-left: 1px solid lightgray;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 47px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 11px;
    width: 11px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: #2196f3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.linear-gradiant-bg {
    background: aliceblue;
    color: #000000;
}

.border-12 {
    border-radius: 5px;
    border: 1px solid rgb(204, 204, 204);
}

.total-apps-div {
    border: 1px solid rgb(223, 223, 223);
    height: 100%;
}

.h-80 {
    height: 100px;
}

.sc-ezbkUp {
    gap: 20px;
}

.text-h4-apps {
    /* padding-left: 13px;
  padding-right: 13px; */
    font-size: 32px;
    padding-top: 0px;
    text-align: center;
}

.text-apps {
    padding-top: 0px;
    font-size: 32px;
    text-align: center;
    /* color: white; */
}

.text-apps-2 {
    padding-top: 0px;
    font-size: 22px;
    text-align: center;
}

.search-box:focus {
    box-shadow: none;
}

.bg-primary {
    --bs-bg-opacity: 1;
    background: #0643f2;
    color: #fff;
}

.rdt_TableHead .rdt_TableHeadRow {
    background: #d8e2ff;
    font-size: 16px;
    font-weight: 700;
    border-radius: 5px;
}

.rdt_TableBody {
    margin-top: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.rdt_TableBody .rdt_TableRow {
    font-size: 17px;
    font-weight: 500;
    background-color: #fbfdff;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fbfdff;
}

.rdt_Pagination {
    padding: 15px;
}

.rdt_Pagination span {
    font-size: 17px;
    font-weight: 700;
}

.rdt_Pagination .bSnpHo {
    gap: 15px;
}

.jMdcfx {
    font-size: 17px;
    font-weight: 700;
}

.bCRTen .hPqfio {
    font-size: 17px;
    font-weight: 700;
}

.hlFmVE {
    font-size: 17px;
    font-weight: 700;
}

.inuiLb {
    gap: 15px;
}

#pagination-first-page {
    border: 1px solid;
}

#pagination-previous-page {
    border: 1px solid;
}

#pagination-next-page {
    border: 1px solid;
}

#pagination-last-page {
    border: 1px solid;
}

.ref-image {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    object-fit: cover;
}

.search-btn-icon:hover {
    background-color: #0643f2;
    color: #fff;
}

.add-ref-btn:hover {
    background-color: #0643f2;
    color: #fff;
}

.rdt_TableCol {
    justify-content: center;
}

.rdt_TableCell {
    display: block;
    justify-content: center;
}

.cdEHwp {
    position: relative;
    /* display: block !important; */
    align-items: center;
    box-sizing: border-box;
    line-height: normal;
    /* padding-left: 16px;
    padding-right: 16px; */
    word-break: break-word;
}

.edit-btn:active {
    color: #ffff !important;
}

.edit-btn:hover {
    color: #ffff !important;
}

.add-ref-btn:active {
    color: #ffff !important;
}

.card {
    /* margin-top: 45px; */
    /* height: 500px; */
    /* width: 1520px; */
    border-radius: 5px !important;
    /* padding: 10px !important; */
}

.card-header {
    display: flex !important;
    justify-content: space-between;
    /* line-height: 30px; */
    background: white !important;
}

.card-title {
    margin: 12px 6px;
    font-weight: 900;
}

.padding-header {
    /* margin-top: 0 !important; */
    padding-top: 3px;
    padding-bottom: 6px;
    padding-left: 20px;
    padding-right: 20px;
}

.eBJjZC {
    display: block;
    text-align: center;
}

.gxajEc {
    display: block;
    text-align: center;
}

.text-red {
    color: red;
}

.MuiBreadcrumbs-li .platform {
    font-weight: 600;
    font-family: "Barlow", sans-serif;
    font-size: 1.2rem;
}

.cust-gap-30 {
    /* gap: 160px; */
}

.card-title-1 {
    margin: 12px 6px;
    font-weight: 900;
    font-size: 30px;
}

.bg-setting-enable {
    background: #22c55e !important;
    color: #ffff !important;
    --bs-btn-border-color: #22c55e !important;
}

.bg-setting-enable:hover {
    background: #22c55e;
    color: #ffff;
    border-color: #22c55e;
}

.bg-setting-enable:active {
    background: #22c55e;
    color: #aaaaaa;
    border-color: #22c55e;
}

.bg-setting-disable {
    background: red !important;
    color: #fff !important;
    border-color: red !important;
}

.bg-setting-disable:hover {
    background: red;
    color: #ffff;
    border-color: red;
}

.bg-setting-disable:active {
    background: red;
    color: #fff;
    border-color: red;
}

.ref-image-2 {
    width: 20px;
    height: 20px;
    border-radius: 10px;
}

/* .btn.show {
  background: #22c55e;
  color: #ffff;
  border-color: #22c55e;
} */
/* .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background: #22c55e;
  color: #ffff;
  border-color: #22c55e;
}
 */
.tab_image {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12) !important;
}

.app-bg {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12) !important;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
}

.app-bg-2 {
    height: 100px;
    width: 100px;
    border-radius: 15px;
    background-size: cover;
    background-position: center;
}

.decrease-fontsize label {
    font-size: 18px !important;
}

/* .cust-p-17 {
  padding: 1.7rem !important;
} */
.dropdown-item:hover {
    text-decoration: none !important;
}

.p-cust-10 {
    padding: 9px !important;
}

.gap-cust-4 {
    gap: 310px;
}

.nav-item .active {
    background: #0643f2 !important;
    color: white !important;
}

.tab-bg-color {
    background: #fff !important;
    color: #000000 !important;
}

.nav-link {
    font-size: 18px;
}

.show-test-ad-in-debug-app-div {
    background: #f8faff;
    border: 1px solid rgb(192, 192, 192);
    /* height: 90px; */
    padding: 15px;
    border-radius: 5px;
}

.main-splash-radio-div {
    background: #ffd4d3;
    border: 1px solid rgb(192, 192, 192);
    /* height: 90px; */
    padding: 15px;
    border-radius: 5px;
}

.main-backpress-ad-radio-div {
    background: #d8e2ff;
    /* height: 90px; */
    padding: 15px;
    border-radius: 5px;
    border: 1px solid rgb(192, 192, 192);
}

.ad-click-count-row {
    background: #e4e4e7;
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    position: relative;
    left: 11px;
}

.inputs-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px 20px;
}

.cust-fs-6-app-more-fields {
    font-size: 0.85rem !important;
}

.icon-trash {
    color: red;
    background: white;
}

.sc-ezjhIU {
    gap: 20px;
}

.platform-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.img-div-1 {
    width: 1.2rem;
}

.hover-icon {
    width: 23px;
    height: 23px;
}

.sc-jrICsz {
    align-items: center;
}

.btn-close {
    --bs-btn-close-color: red;
    --bs-btn-close-bg: url("../icons/svgs/cancel.svg");
    --bs-btn-close-opacity: 1.5;
    --bs-btn-close-hover-opacity: 1.5;
    --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    box-sizing: content-box;
    width: 2em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: var(--bs-btn-close-color);
    background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    opacity: var(--bs-btn-close-opacity);
    font-size: 1.7em;
}

.btn-close:focus {
    box-shadow: none;
}

.active-btn {
    background-color: rgb(228, 248, 228) !important;
    font-weight: 500 !important;
    color: rgb(4, 145, 4) !important;
}

.active-btn:hover {
    background-color: rgb(228, 248, 228) !important;
    font-weight: 500 !important;
    color: rgb(4, 145, 4) !important;
}

.inactive-btn {
    background-color: rgb(238, 238, 238) !important;
    font-weight: 500 !important;
}

.inactive-btn:hover {
    background-color: rgb(238, 238, 238) !important;
    font-weight: 500 !important;
}

.reject-btn {
    background-color: #ffd3e6 !important;
    font-weight: 500 !important;
    color: #ff0062 !important;
}

.reject-btn:hover {
    background-color: #ffd3e6 !important;
    font-weight: 500 !important;
    color: #ff0062 !important;
}

.remove-btn {
    background-color: #ffd3d3 !important;
    font-weight: 500 !important;
    color: #ff0000 !important;
}

.remove-btn:hover {
    background-color: #ffd3d3 !important;
    font-weight: 500 !important;
    color: #ff0000 !important;
}

.suspend-btn {
    background-color: #d3e5ff !important;
    font-weight: 500 !important;
    color: #007dff !important;
}

.suspend-btn:hover {
    background-color: #d3e5ff !important;
    font-weight: 500 !important;
    color: #007dff !important;
}

.mb-0 {
    margin-bottom: 0rem !important;
}

.image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    /* Adjust the gap value as needed */
}

.image-row {
    display: flex;
    gap: 10px;
    /* Adjust the gap value as needed */
}

@media screen and (max-width: 1440px) {
    .cust-gap-5 {
        /* gap: 11rem !important; */
    }
}

@media screen and (min-width: 388px) {
    .bg-setting {
        width: 100px;
    }
}

@media screen and (max-width: 510px) {
    .full-width-button button {
        width: 100%;
        /* margin-left: 20px;
    margin-right: 20px; */
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .full-width-button button {
        width: 100%;
        margin-left: 20px;
        margin-right: 20px;
    }

    .cust-fs-5 {
        font-size: 0.6rem !important;
    }
}

@media screen and (max-width: 1024px) {
    .gap-cust-4 {
        gap: 172px;
    }

    .MuiTabs-flexContainer {
        gap: 100px;
    }

    .cust-gap-5 {
        gap: 1rem !important;
    }

    .app-bg-img {
        display: flex;
        justify-content: center;
    }

    .app-status {
        display: flex;
        justify-content: center;
    }

    .cust-fs-5 {
        font-size: 0.6rem !important;
    }
}

@media screen and (max-width: 991px) {
    .gap-4 {
        gap: 1rem !important;
    }

    .gap-cust-4 {
        gap: 50px;
    }

    .card-box {
        margin-bottom: 30px;
    }

    .w-20 {
        width: 50%;
    }

    .thumb-lg {
        width: 70px;
        height: 70px;
    }

    .suspended-line {
        width: 49px;
    }

    .suspended {
        right: -19px;
    }

    .suspended-svg {
        height: 50px;
    }

    .suspended-text {
        top: 12px;
        right: 18px;

        font-size: 10px;
    }

    .details-text {
        font-size: 14px;
        line-height: 14px;
    }

    .text-h4-apps {
        /* padding-left: 12px;
    padding-right: 12px; */
        font-size: 14px;
        padding-top: 12px;
        font-size: 16px;
    }

    .text-apps {
        padding-top: 3px;
        font-size: 16px;
        /* color: white; */
    }

    .w-30 {
        width: 33%;
    }

    .MuiTabs-flexContainer {
        gap: 80px;
    }

    .cust-col-12 {
        width: 100% !important;
    }

    .app-details-sec-1 {
        width: 100%;
    }

    .app-details-sec-2 {
        width: 100%;
    }

    .splash-ad-col-4 {
        width: 100%;
    }

    .cust-ad-count-col-4 {
        width: 50%;
        padding: 10px;
    }

    .inputs-div {
        display: block;
    }

    .input-res-div {
        padding: 10px;
    }

    .cust-col-6-app-more-fields {
        width: 100%;
    }

    .input-res-div-app-more-field {
        padding: 5px;
    }

    .sc-fKwBvW {
        position: relative;
        width: max-content !important;
        display: table;
    }

    .sc-fKVrsT {
        position: relative;
        width: max-content !important;
        display: table;
    }
}

@media screen and (max-width: 768px) {
    .col-2 {
        width: 33%;
    }

    .no-data-img img {
        width: 100%;
    }

    .text-h4-apps {
        /* padding-left: 12px;
    padding-right: 12px; */
        font-size: 14px;
    }

    .text-apps {
        padding-top: 0px;
        font-size: 12px;
        /* color: white; */
    }

    .text-apps-2 {
        padding-top: 0px;
        font-size: 12px;
        /* color: white; */
    }

    .app-name {
        text-align: center;
    }

    .app-package-name {
        text-align: center;
    }

    .cust-col2-rs {
        width: 22% !important;
    }

    .cust-col-3-1-rs {
        width: 24%;
    }

    .cust-col-3-2-rs {
        width: 24%;
    }

    .cust-col-4-rs {
        width: 29%;
    }

    .rs-col-2 {
        width: 30%;
    }

    .rs-col-10 {
        width: 70%;
    }

    .cust-fs-5 {
        font-size: 1rem !important;
    }

    .nav-link {
        font-size: 15px;
    }

    .show-test-ad-in-debug-app-div {
        padding: 20px;
    }

    .cust-show-test-ad-col-6 {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .align-items-center {
        /* width: 100%; */
    }

    .col-12 {
        width: 100%;
    }

    .upload-ref-img-div {
        margin-top: 15px;
    }

    .modal-content {
        width: 100%;
    }

    .MuiBreadcrumbs-li .css-1gqyz35-MuiTypography-root {
        font-size: 18px;
    }

    .app-publish-div {
        justify-content: center;
        width: 100%;
    }

    .main-content-div {
        text-align: center;
        width: 100%;
        padding: 30px 90px;
    }

    .img-platform {
        height: 60px;
        width: 60px;
    }

    .main-div {
        width: 100%;
    }

    .w-20 {
        width: 100%;
    }

    .first-img-div {
        width: 100%;
        justify-content: center;
        display: flex;
    }

    .second-pc-name-div {
        width: 35%;
        margin-top: 20px;
    }

    .second-pc-name-div p {
        font-size: 18px;
        padding-left: 20px;
    }

    .third-apps-details-div {
        width: 62%;
        margin-top: 20px;
    }

    .total-apps-no {
        width: 50%;
    }

    .console-bg-img {
        height: 100px;
        width: 100px;
    }

    .mobile-border {
        border: 1px solid #a1a1aa;
    }

    .first-border {
        border-right: none;
        width: 100%;
        margin-top: 10px;
    }

    .second-border {
        width: 100%;
    }

    .total-apps-text {
        width: 50%;
    }

    .details-text {
        font-size: 16px;
        line-height: 16px;
    }

    .cust-row2-padding {
        margin-top: 0 !important;
    }

    .text-h4-apps {
        /* padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px; */
    }

    .text-apps {
        padding-top: 1px;
        font-size: 12px;
    }

    .gap-cust-4 {
        gap: 12px;
    }

    .MuiTabs-flexContainer {
        gap: 0px;
    }

    .rs-col-9 {
        width: 53%;
    }

    .rs-col-9-2 {
        width: 40%;
    }

    .app-deatails-div {
        text-align: center;
    }

    .rs-col-2 {
        width: 20%;
    }

    .rs-col-10 {
        width: 60%;
    }

    .app-bg {
        height: 100px;
        width: 100px;
    }

    .cust-col-3-1-rs {
        width: 40%;
    }

    .cust-col-4-rs {
        width: 66%;
        margin-top: 15px;
    }

    .nav-link {
        font-size: 10px;
    }

    .cust-h4 {
        font-size: 16px;
    }

    .cust-show-test-ad-main-col-4 {
        width: 50%;
        padding: 7px;
    }

    .cust-main-splash-ad-col-4 {
        width: 50%;
        padding: 7px;
    }

    .cust-main-backpress-ad-col-4 {
        width: 50%;
        padding: 7px;
    }

    .cust-row-gap-2 {
        gap: 0;
    }

    .cust-row-gap-1 {
        gap: 0;
    }

    .cust-show-test-ad-col-6 {
        width: 50%;
    }

    .splash-ad-col-4 {
        width: 33%;
    }

    .cust-ad-count-col-4 {
        width: 100%;
        padding: 10px;
    }

    .cust-fs-6-radio-apps-more-field {
        font-size: 0.55rem !important;
    }

    .cust-fs-6-app-more-fields {
        font-size: 0.55rem !important;
    }

    .apply-tex {
        padding: 7px;
    }

    .cust-gap-1-app-more-fields {
        gap: 0 !important;
    }

    .cust-fs-5-app-more-fields {
        font-size: 0.7rem !important;
    }

    .rs-picker-daterange {
        width: 100%;
    }

    .select-div {
        width: 100%;
    }

    .sc-ezbkUp {
        gap: 5px;
    }

    .inuiLb {
        gap: 5px;
    }

    .nav-link {
        font-size: 12px !important;
    }

    .cust-fs-3 {
        font-size: 16px !important;
    }

    .cust-fs-5 {
        font-size: 12px !important;
    }

    .img-div-res {
        width: 40%;
        height: 40%;
    }

    .img-res {
        width: 100%;
        height: 100%;
    }

    .text-16 {
        font-size: 15px !important;
    }

    .gap-4 {
        gap: 10px !important;
    }

    .justify-content-between {
        justify-content: space-around !important;
    }

    .modal-dialog-scrollable .modal-body {
        overflow-x: hidden;
    }
}