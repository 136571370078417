.root-div-2 {
  position: relative;
  align-items: flex-start;
  outline: solid 1px #e4e4e7;
  outline-offset: -1px;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0px 0px 2px 0px #00000040;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  align-items: center;
  width: 100%;
}
.publishPlatformMonetizePlatfor {
  color: #000;
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  left: 20px;
  top: 20px;

  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
  padding-right: 20px;
}
.line3 {
  left: 333px;
  top: 16px;

  border-left: solid 1px #a5a5a5;
  margin-bottom: -1px;

  height: 30px;
}
.frame29 {
  left: 353px;
  top: 14px;
  overflow: visible;
  padding-left: 20px;
}
@media screen and (min-width: 373px) and (max-width: 459px) {
  .frame29 {
    margin-top: 1.3rem;
  }
}
.icons {
  width: auto;
  max-width: 325px;
  height: 35px;
}
.frame24 {
  display: flex;

  top: 18%;
  align-items: center;
  overflow: visible;
  padding-left: 20px;
}
.frame22 {
  width: min-content;
  height: min-content;
}
.admin {
  color: #232526;
  font-size: 16px;
  line-height: 27px;
  font-weight: 500;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.ellipse4 {
  width: 42px;
  height: 42px;
  overflow: visible;
}
.icon2 {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 480px) {
  .publishPlatformMonetizePlatfor {
    width: 100%;
    height: min-content;
    text-align: -webkit-match-parent;
    align-items: center;
    white-space: unset;
    flex-direction: column;
    padding-right: 20px;
  }
}
