.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --cui-gutter-x: 1.5rem;
  --cui-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--cui-gutter-x) * 0.5);
  padding-left: calc(var(--cui-gutter-x) * 0.5);
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.app-manager-dashbord-continer {
  max-width: 100% !important;
}
@media only screen and (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100% !important;
  }
}
.margin {
  margin-left: auto !important;
  margin-right: auto !important;
}
