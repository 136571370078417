@import "rsuite/dist/rsuite.css";

.search-box:focus {
    box-shadow: none;
}

.bg-primary {
    --bs-bg-opacity: 1;
    background: #0643f2;
    color: #fff;
}

.rdt_TableHead .rdt_TableHeadRow {
    background: #d8e2ff;
    font-size: 16px;
    font-weight: 700;
    border-radius: 5px;
}

.rdt_TableBody {
    margin-top: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.rdt_TableBody .rdt_TableRow {
    font-size: 17px;
    font-weight: 500;
    background-color: #fbfdff;
    padding-top: 10px;
    padding-bottom: 10px;
    /* background-color: #fff; */
}

.rdt_Pagination {
    padding: 15px;
}

.rdt_Pagination span {
    font-size: 17px;
    font-weight: 700;
}

.rdt_Pagination .bSnpHo {
    gap: 15px;
}

.active-btn {
    background-color: rgb(228, 248, 228);
    font-weight: 500;
    color: rgb(4, 145, 4);
}

.active-btn:hover {
    background-color: rgb(228, 248, 228);
    font-weight: 500;
    color: rgb(4, 145, 4);
}

.inactive-btn {
    background-color: rgb(238, 238, 238);
    font-weight: 500;
}

.inactive-btn:hover {
    background-color: rgb(238, 238, 238);
    font-weight: 500;
}

.jMdcfx {
    font-size: 17px;
    font-weight: 700;
}

.bCRTen .hPqfio {
    font-size: 17px;
    font-weight: 700;
}

.hlFmVE {
    font-size: 17px;
    font-weight: 700;
}

.inuiLb {
    gap: 15px;
}

#pagination-first-page {
    border: 1px solid;
}

#pagination-previous-page {
    border: 1px solid;
}

#pagination-next-page {
    border: 1px solid;
}

#pagination-last-page {
    border: 1px solid;
}

.ref-image {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    object-fit: cover;
}

.upload-ref-img {
    width: 100px;
    height: 100px;
}

.search-btn-icon:hover {
    background-color: #0643f2;
    color: #fff;
}

.add-ref-btn:hover {
    background-color: #0643f2;
    color: #fff;
}

.rdt_TableCol {
    justify-content: center;
}

.rdt_TableCell {
    display: flex !important;
    justify-content: center !important;
}

.edit-btn:active {
    color: #ffff !important;
}

.edit-btn:hover {
    color: #ffff !important;
}

.add-ref-btn:active {
    color: #ffff !important;
}

.card {
    /* margin-top: 45px; */
    /* height: 500px; */
    /* width: 1520px; */
    border-radius: 12px !important;
    /* padding: 10px !important; */
}

.card-header {
    display: flex !important;
    justify-content: space-between;
    /* line-height: 30px; */
    background: white !important;
}

.card-title {
    margin: 12px 6px;
    font-weight: 900;
}

.padding-header {
    /* margin-top: 0 !important; */
    padding-top: 3px;
    padding-bottom: 6px;
    padding-left: 20px;
    padding-right: 20px;
}

.gSejHU {
    font-size: 22px;
}

.MuiTabs-flexContainer {
    gap: 190px;
}

.sc-ezbkUp {
    gap: 20px;
}

.icon-trash {
    color: red;
}

.live-app-btn:hover {
    color: #fff;
}

.live-app-btn:focus {
    color: #fff;
}

.live-app-btn:active {
    color: #fff;
}

.sc-ezjhIU {
    gap: 20px;
}

.btn-close:focus {
    box-shadow: none;
}

.btn-close {
    --bs-btn-close-color: red;
    --bs-btn-close-bg: url("../icons/svgs/cancel.svg");
    --bs-btn-close-opacity: 1.5;
    --bs-btn-close-hover-opacity: 1.5;
    --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    box-sizing: content-box;
    width: 2em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: var(--bs-btn-close-color);
    background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    opacity: var(--bs-btn-close-opacity);
    font-size: 1.7em;
}

@media screen and (max-width: 1024px) {
    .MuiTabs-flexContainer {
        gap: 90px;
    }
}

@media screen and (max-width: 991px) {
    .gap-4 {
        gap: 1rem !important;
    }

    .MuiTabs-flexContainer {
        gap: 0px;
    }
}

@media screen and (max-width: 767px) {
    .col-12 {
        width: 50%;
    }

    .cust-col-6 {
        width: 100% !important;
        padding: 10px;
    }
}

@media screen and (max-width: 480px) {
    .align-items-center {
        width: 100%;
    }

    .col-12 {
        width: 100%;
    }

    .upload-ref-img-div {
        margin-top: 15px;
    }

    .MuiTabs-flexContainer {
        gap: 0px;
    }

    .add-ref-btn {
        width: 100%;
    }

    .rs-picker-daterange {
        width: 100%;
    }

    .sc-ezbkUp {
        gap: 5px;
    }

    .fs-6 {
        font-size: 14px !important;
    }

    .img-div-res {
        width: 40%;
        height: 40%;
    }

    .img-res {
        width: 100%;
        height: 100%;
    }

    .text-16 {
        font-size: 16px !important;
    }

    .gap-4 {
        gap: 10px !important;
    }

    .justify-content-between {
        justify-content: space-around !important;
    }

    .modal-dialog-scrollable .modal-body {
        overflow-x: hidden;
    }
}