.search-box:focus {
    box-shadow: none;
}

.bg-primary {
    --bs-bg-opacity: 1;
    background: #0643f2;
    color: #fff;
}

.rdt_TableHead .rdt_TableHeadRow {
    background: #d8e2ff;
    font-size: 16px;
    font-weight: 700;
    border-radius: 5px;
}

.rdt_TableBody {
    margin-top: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.rdt_TableBody .rdt_TableRow {
    font-size: 17px;
    font-weight: 500;
    background-color: #fbfdff;
    padding-top: 10px;
    padding-bottom: 10px;
    /* background-color: #fff; */
}

.app-console-div {
    background-color: #fdfeff !important;
    border: 2px solid rgb(233, 233, 233);
    border-radius: 10px;
    position: relative;
}

.app-console-div .app-cosole-img {
    position: absolute;
    top: -20%;
    left: 5%;
    height: 120px;
    width: 120px;
    border-radius: 50%;
}

.app-console-div .app-console-sub {
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.app-console-div .app-console-sub .app-count {
    padding: 8px;
    background-color: rgba(255, 255, 255, 0.295);
    color: transparent;
    font-size: 16px;
    font-weight: 600;
}

.app-console-div .app-console-sub .app-status {
    color: white;
    font-size: 12px;
}

.rdt_Pagination {
    padding: 15px;
}

.rdt_Pagination span {
    font-size: 17px;
    font-weight: 700;
}

.rdt_Pagination .bSnpHo {
    gap: 15px;
}

.jMdcfx {
    font-size: 17px;
    font-weight: 700;
}

.bCRTen .hPqfio {
    font-size: 17px;
    font-weight: 700;
}

.hlFmVE {
    font-size: 17px;
    font-weight: 700;
}

.inuiLb {
    gap: 15px;
}

#pagination-first-page {
    border: 1px solid;
}

#pagination-previous-page {
    border: 1px solid;
}

#pagination-next-page {
    border: 1px solid;
}

#pagination-last-page {
    border: 1px solid;
}

.ref-image {
    width: 40px;
    height: 40px;
    border-radius: 5px;
}

.upload-ref-img {
    width: 100px;
    height: 100px;
}

.search-btn-icon:hover {
    background-color: #0643f2;
    color: #fff;
}

.add-ref-btn:hover {
    background-color: #0643f2;
    color: #fff;
}

.rdt_TableCol {
    justify-content: center;
}

.rdt_TableCell {
    display: flex;
    justify-content: center;
}

.card {
    border-radius: 12px !important;
}

.card-header {
    display: flex !important;
    justify-content: space-between;
    background: white !important;
}

.card-title {
    margin: 12px 6px;
    font-weight: 900;
}

.icon-trash {
    color: red;
    background: white;
}

.icon-trash-all {
    margin-left: 10px;
    color: red;
    background: white;
}

.create-app-btn:active {
    color: #ffff !important;
}

.create-app-btn:hover {
    color: #ffff !important;
}

.rdt_TableRow.rdt_Selected {
    /* Styles for selected rows */
    border-radius: 8px;
    /* Set the border radius for selected rows */
}

.modal-content {
    width: 100%;
}

.modal-footer {
    display: flex;
    justify-content: center;
}

.MuiBreadcrumbs-li .platform {
    font-weight: 600;
    font-family: "Barlow", sans-serif;
    font-size: 1.2rem;
}

.main-content-div {
    /* padding: 32px; */
    background-color: #f8fdff;
    border: 1px solid rgb(233, 233, 233);
    padding: 10px 35px;
    border-radius: 10px;
    height: 100%;
    width: 182px;
}

.selectedDiv {
    border: 1px solid blue;
}

.img-platform-div {
    border-radius: 50%;
    padding: 30px;
}

.img-platform {
    height: auto;
    width: auto;
    max-width: 50px;
}

.gap-6 {
    gap: 25px;
}

.over-img {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5);
    /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    transition: 0.5s ease;
    opacity: 0;
    color: white;
    font-size: 20px;
    text-align: center;
}

.main-div:hover .over-img {
    opacity: 1;
}

.hover-icon {
    cursor: pointer;
}

.padding-header {
    padding-top: 0;
    padding-bottom: 4px;
    padding-left: 20px;
    padding-right: 20px;
}

.img-div {
    width: 90px;
    height: 90px;
    border: 2px solid white;
    box-shadow: 0px 2px 9px;
    border-radius: 50%;
    position: relative;

    overflow: hidden;
}

.img-div img {
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: initial;
    width: auto;
}

.main-content-list-div {
    background: #f3f2f2;
    padding: 20px 11px;
    border-radius: 10px;
}

.play-cosole-name {
    margin-top: -10px;
    font-size: 20px;
    font-weight: 600;
}

.main-app-reject {
    background: #f34a73;
    text-align: center;
    height: 100%;
    padding: 0 10px;
    border-radius: 10px;
}

.reject-app-no {
    padding-top: 10px;
    color: #fff;
    text-align: center;
}

.reject-app-text {
    color: #ffff;
}

.margin-cust1 {
    margin-top: 10px;
}

.main-app-live {
    background: #04a03e;
    text-align: center;
    height: 100%;
    padding: 0 10px;
    border-radius: 10px;
}

.live-app-no {
    padding-top: 10px;
    color: #fff;
    text-align: center;
}

.live-app-text {
    color: #ffff;
}

.main-app-suspend {
    background: #ff0c0c;
    text-align: center;
    height: 100%;
    padding: 0 10px;
    border-radius: 10px;
}

.suspend-app-no {
    padding-top: 10px;
    color: #fff;
    text-align: center;
}

.suspend-app-text {
    color: #ffff;
}

.content {
    padding-top: 15px;
}

.console-logo {
    margin-top: -60px;
}

.first-row {
    /* padding: 0 20px; */
}

.whole-div {
    border: 1px solid rgb(211, 211, 250);
    border-radius: 15px;
}

.img-main-div {
    width: 120px;
    height: 120px;
    border: 2px solid white;
    box-shadow: 0px 2px 9px;
    border-radius: 50%;
    position: relative;

    overflow: hidden;
}

.img-main-div img {
    height: 100%;
    width: 100%;
}

.console-name {
    font-size: 15px;
    font-weight: 600;
    padding: 15px;
}

.icon-class {
    background: lightblue;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
}

.main-data {
    padding: 0 22px;
}

.card-box {
    padding: 5px;
    /* margin-bottom: 30px; */
    background-color: #f8fdff;
    border: 1px solid rgb(233, 233, 233);
    border-radius: 15px;
}

.thumb-lg {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
}

.img-thumbnail {
    background-color: #fff;
    border: 1px solid #dee2e6;
    max-width: 100%;
    height: auto;
}

.text-pink {
    color: #ff679b !important;
}

.text-muted {
    color: #98a6ad !important;
    font-size: 10px !important;
}

.inner-div {
    padding: 0.2rem 0 !important;
    border-radius: 5px !important;
    background-color: rgba(0, 0, 0, 0.027) !important;
    height: 75px !important;
}

h4 {
    line-height: 18px;
    padding-top: 7px;
    font-size: 18px;
    font-weight: 600;
}

.w-20 {
    width: 20%;
}

.btn-close {
    --bs-btn-close-color: red;
    --bs-btn-close-bg: url("../icons/svgs/cancel.svg");
    --bs-btn-close-opacity: 1.5;
    --bs-btn-close-hover-opacity: 1.5;
    --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    box-sizing: content-box;
    width: 2em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: var(--bs-btn-close-color);
    background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    opacity: var(--bs-btn-close-opacity);
    font-size: 1.7em;
}

.btn-close:focus {
    box-shadow: none;
}

@media screen and (max-width: 991px) {
    .gap-4 {
        gap: 1rem !important;
    }

    .card-box {
        margin-bottom: 30px;
    }

    .w-20 {
        width: 25%;
    }

    .thumb-lg {
        width: 90px;
        height: 90px;
    }
}

@media screen and (max-width: 767px) {
    .col-12 {
        width: 50%;
    }

    .no-data-img img {
        width: 100%;
    }

    .w-20 {
        width: 50%;
    }
}

@media screen and (max-width: 480px) {
    .align-items-center {
        width: 100%;
    }

    .col-12 {
        width: 100%;
    }

    .upload-ref-img-div {
        margin-top: 15px;
    }

    .modal-content {
        width: 100%;
    }

    .MuiBreadcrumbs-li .css-1gqyz35-MuiTypography-root {
        font-size: 18px;
    }

    .app-publish-div {
        justify-content: center;
        width: 100%;
    }

    .main-content-div {
        text-align: center;
        width: 100%;
        padding: 25px 60px;
    }

    .img-platform {
        height: 60px;
        width: 60px;
    }

    .main-div {
        width: 100%;
    }

    .w-20 {
        width: 100%;
    }

    .add-ref-btn {
        width: 100%;
    }

    .col-6 {
        /* width: 100%; */
    }

    .col-ex-small {
        width: 50%;
    }
}

@media screen and (max-width: 480px) {
    .col-6 {
        width: 100%;
    }

    .img-div-res {
        width: 40%;
        height: 40%;
    }

    .img-res {
        width: 100%;
        height: 100%;
    }

    .text-16 {
        font-size: 16px !important;
    }

    .gap-4 {
        gap: 0 !important;
    }

    .justify-content-between {
        justify-content: space-around !important;
    }

    .modal-dialog-scrollable .modal-body {
        overflow-x: hidden;
    }

    .no-data-img img {
        width: 75%;
    }

    .mt-5 {
        margin-top: 1.2rem !important;
    }
}