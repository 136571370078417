@import "rsuite/dist/rsuite.css";

.search-box:focus {
    box-shadow: none;
}

.bg-primary {
    --bs-bg-opacity: 1;
    background: #0643f2;
    color: #fff;
}

.rdt_TableHead .rdt_TableHeadRow {
    background: #d8e2ff;
    font-size: 16px;
    font-weight: 700;
    border-radius: 5px;
}

.inner-div {
    padding: 0.2rem 0;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.027);
    height: 64px;
}

.rdt_TableBody {
    margin-top: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.rdt_TableBody .rdt_TableRow {
    font-size: 17px;
    font-weight: 500;
    background-color: #fbfdff;
    padding-top: 10px;
    padding-bottom: 10px;
    /* background-color: #fff; */
}

.rdt_Pagination {
    padding: 15px;
}

.rdt_Pagination span {
    font-size: 17px;
    font-weight: 700;
}

.rdt_Pagination .bSnpHo {
    gap: 15px;
}

.jMdcfx {
    font-size: 14px;
    font-weight: 700;
}

.bCRTen .hPqfio {
    font-size: 17px;
    font-weight: 700;
}

.hlFmVE {
    font-size: 17px;
    font-weight: 700;
}

.inuiLb {
    gap: 15px;
}

#pagination-first-page {
    border: 1px solid;
}

#pagination-previous-page {
    border: 1px solid;
}

#pagination-next-page {
    border: 1px solid;
}

#pagination-last-page {
    border: 1px solid;
}

.search-btn-icon:hover {
    background-color: #0643f2;
    color: #fff;
}

.add-ref-btn:hover {
    background-color: #0643f2;
    color: #fff;
}

.rdt_TableCol {
    justify-content: center;
}

.rdt_TableCell {
    display: flex;
    justify-content: center;
}

.add-ref-btn:active {
    color: #ffff !important;
}

.card {
    /* margin-top: 45px; */
    /* height: 500px; */
    /* width: 1520px; */
    border-radius: 12px !important;
    /* padding: 10px !important; */
}

.card-header {
    display: flex !important;
    justify-content: space-between;
    /* line-height: 30px; */
    background: white !important;
}

.card-title {
    margin: 12px 6px;
    font-weight: 900;
}

.padding-header {
    /* margin-top: 0 !important; */
    padding-top: 3px;
    padding-bottom: 6px;
    padding-left: 20px;
    padding-right: 20px;
}

.MuiBreadcrumbs-li .platform {
    font-weight: 600;
    font-family: "Barlow", sans-serif;
    font-size: 1.2rem;
}

.card-box {
    padding: 5px;
    background-color: #f8fdff;
    border: 1px solid rgb(233, 233, 233);
    border-radius: 15px;
}

.thumb-lg {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
}

.img-thumbnail {
    /* padding: .25rem; */
    background-color: #fff;
    border: 1px solid #dee2e6;
    /* border-radius: .25rem; */
    max-width: 100%;
    height: auto;
}

.text-pink {
    color: #ff679b !important;
}

.text-muted {
    color: #98a6ad !important;
    font-size: 12px !important;
    line-height: 12px !important;
}

h4 {
    line-height: 18px;
    padding-top: 7px;
    font-size: 18px;
    font-weight: 600;
}

.w-20 {
    width: 20%;
}

.over-img {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5);
    /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    transition: 0.5s ease;
    opacity: 0;
    color: white;
    font-size: 20px;
    text-align: center;
}

.card-box:hover .over-img {
    opacity: 1;
}

.hover-icon {
    cursor: pointer;
}

.suspended {
    position: absolute;
    top: 0;
    right: 0;
}

.css-heg063-MuiTabs-flexContainer {
    justify-content: space-between;
    width: 100%;
}

.css-k008qs {
    justify-content: space-between;
    width: 100%;
}

@media screen and (max-width: 480px) {
    .css-heg063-MuiTabs-flexContainer {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .css-k008qs {
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

@media screen and (min-width: 481px) and (max-width: 1199px) {
    .css-heg063-MuiTabs-flexContainer {
        display: block !important;
        display: -webkit-box !important;
    }

    .css-k008qs {
        display: block !important;
        display: -webkit-box !important;
    }
}

.suspended-text {
    position: absolute;
    top: 19px;
    right: 0px;
    transform: rotate(40deg);
    color: white;
}

.suspended-gb {
    background: #ffc8c8;
}

.suspended-line {
    position: absolute;
    width: 71px;
    /* height: 25px; */
    top: 9px;
    right: 13px;
}

.suspended-line img {
    height: 100%;
    width: 100%;
}

.upload-ref-img {
    width: 100px;
    height: 100px;
}

.form-label {
    font-size: 16px;
    /* font-weight: 500; */
    color: black;
}

.console-bg-img {
    height: 85px;
    width: 85px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
}

.app-reject-div {
    background: #f3f2f2;
    color: #000000;
}

.text-h4-apps {
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 16px;
    text-align: center;
}

.text-apps {
    padding-top: 8px;
    font-size: 13px;
    text-align: center;
    /* color: white; */
}

.app-live-div {
    background: #f3f2f2;
    color: #000000;
}

.app-suspended-div {
    background: #f3f2f2;
    color: #000000;
}

.first-border {
    border-right: 2px solid #a1a1aa;
}

.details-text {
    font-size: 13px;
    line-height: 13px;
}

.cust-row2-padding {
    padding: 10px;
}

.MuiTabs-flexContainer {
    gap: 200px;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    text-transform: capitalize !important;
}

.ref-image {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    object-fit: cover;
}

.css-13xfq8m-MuiTabPanel-root {
    padding: 12px 0 !important;
}

.border-left {
    border-left: 1px solid #9ec5fe !important;
}

.border-right {
    border-right: 1px solid #9ec5fe !important;
}

.pc-name {
    font-size: 20px;
    line-height: 20px;
    padding: 20px 0;
}

.total-apps-text-width {
    width: 21%;
}

.third-apps-details-div {
    color: #ffff;
    width: 49%;
}

.gap-cust-4 {
    gap: 310px;
}

.w-30 {
    width: 30%;
}

.h-80 {
    height: 80px;
}

.border-12 {
    border-radius: 12px;
}

.btn-close {
    --bs-btn-close-color: red;
    --bs-btn-close-bg: url("../icons/svgs/cancel.svg");
    --bs-btn-close-opacity: 1.5;
    --bs-btn-close-hover-opacity: 1.5;
    --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    box-sizing: content-box;
    width: 2em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: var(--bs-btn-close-color);
    background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    opacity: var(--bs-btn-close-opacity);
    font-size: 1.7em;
}

.btn-close:focus {
    box-shadow: none;
}

@media screen and (max-width: 1024px) {
    .gap-cust-4 {
        gap: 172px;
    }

    .MuiTabs-flexContainer {
        gap: 180px;
    }
}

@media screen and (max-width: 991px) {
    .gap-4 {
        gap: 1rem !important;
    }

    .gap-cust-4 {
        gap: 30px;
    }

    .card-box {
        margin-bottom: 30px;
    }

    .w-20 {
        width: 50%;
    }

    .thumb-lg {
        width: 100px;
        height: 100px;
    }

    .suspended-line {
        width: 70px;
    }

    .suspended {
        right: -19px;
    }

    .suspended-svg {
        height: 50px;
    }

    .suspended-text {
        top: 12px;
        right: 18px;

        font-size: 10px;
    }

    .details-text {
        font-size: 11px;
        line-height: 11px;
    }

    .text-h4-apps {
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 12px;
        font-size: 15px;
    }

    .text-apps {
        padding-top: 3px;
        font-size: 12px;
        /* color: white; */
    }

    .w-30 {
        width: 33%;
    }

    .MuiTabs-flexContainer {
        gap: 80px;
    }
}

@media screen and (max-width: 768px) {
    .col-12 {
        width: 50%;
    }

    .no-data-img img {
        width: 100%;
    }

    .w-20 {
        width: 50%;
    }

    .suspended {
        right: -19px;
    }

    .suspended-svg {
        height: 50px;
    }

    .suspended-text {
        top: 13px;
        right: 16px;
        /* transform: rotate(42deg);
    color: white; */
    }

    .text-h4-apps {
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 12px;
        font-size: 14px;
    }

    .text-apps {
        padding-top: 6px;
        font-size: 11px;
        /* color: white; */
    }
}

@media screen and (max-width: 575px) {
    .row-view-app {
        width: 100% !important;
    }
}

@media screen and (max-width: 480px) {
    .align-items-center {
        width: 100%;
    }

    .col-12 {
        width: 100%;
    }

    .upload-ref-img-div {
        margin-top: 15px;
    }

    .modal-content {
        width: 100%;
    }

    .MuiBreadcrumbs-li .css-1gqyz35-MuiTypography-root {
        font-size: 18px;
    }

    .app-publish-div {
        justify-content: center;
        width: 100%;
    }

    .main-content-div {
        text-align: center;
        width: 100%;
        padding: 30px 90px;
    }

    .img-platform {
        height: 60px;
        width: 60px;
    }

    .main-div {
        width: 100%;
    }

    .w-20 {
        width: 100%;
    }

    .first-img-div {
        width: 100%;
        justify-content: center;
        display: flex;
    }

    .second-pc-name-div {
        width: 35%;
        margin-top: 20px;
    }

    .second-pc-name-div p {
        font-size: 18px;
        padding-left: 20px;
    }

    .third-apps-details-div {
        width: 62%;
        margin-top: 20px;
    }

    .console-bg-img {
        height: 100px;
        width: 100px;
    }

    .mobile-border {
        border: 1px solid #a1a1aa;
    }

    .first-border {
        border-right: none;
        width: 100%;
        margin-top: 10px;
    }

    .second-border {
        width: 100%;
    }

    .details-text {
        font-size: 14px;
        line-height: 14px;
    }

    .cust-row2-padding {
        margin-top: 0 !important;
    }

    .text-h4-apps {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
    }

    .text-apps {
        padding-top: 2px;
        font-size: 10px;
    }

    .gap-cust-4-1 {
        gap: 10px;
    }

    .MuiTabs-flexContainer {
        gap: 25px;
    }

    .select-div {
        width: 100%;
    }

    .add-ref-btn {
        width: 100%;
    }

    .col-6-pagination {
        width: 100%;
    }

    .modal-dialog-scrollable .modal-body {
        overflow-x: Clip;
    }

    .no-data-img img {
        width: 75%;
    }

    .img-div-res {
        width: 40%;
        height: 40%;
    }

    .img-res {
        width: 100%;
        height: 100%;
    }

    .text-16 {
        font-size: 15px !important;
    }

    .gap-4-cust {
        gap: 10px !important;
    }

    .justify-content-between {
        justify-content: space-around !important;
    }

    .modal-dialog-scrollable .modal-body {
        overflow-x: hidden;
    }
}



.suspend_bg {
    background-color: #ffd3e6 !important;
    overflow: hidden;
    position: relative;
}

.live_bg {
    background-color: rgb(228, 248, 228) !important;
}

.rejectbtn_bg {
    background: #F34A73;

}

.livebtn_bg {
    background: #04A03E;


}

.suspendbtn_bg {
    background: #FF0C0C;


}

.reviewbtn_bg {
    background: lightseagreen;
}

.console_bg_btn {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
}

.depend_btn {
    color: white;
    border-radius: 5px;

    p {
        color: white !important;
    }
}

.suspend_line {
    position: absolute;
    top: 30px;
    right: -39px;
    background: #AC2222;
    transform: rotate(46deg);
    width: 154px;
    color: white;
    font-weight: 700;
}