.bG {
  margin-top: 20px;
  outline: solid 1.5496px #e4e4e7;
  outline-offset: -1px;
  border-radius: 15.4956px;
  background-color: #fff;
  padding: 15px 15px;
}
.line-3 {
  position: absolute;
  right: -8%;
  top: 0%;
  height: 100%;
  width: 1px;
  background-color: #a5a5a5;
}
.line-4 {
  position: absolute;
  left: -4%;
  top: 0%;
  height: 100%;
  width: 1px;
  background-color: #a5a5a5;
}
.frame4144 {
  display: flex;
  flex-wrap: wrap;
  height: min-content;
  align-items: center;
}
.group4153 {
  display: flex;
  left: 11px;
  width: auto;
  max-width: 54px;
  height: 54px;
  overflow: visible;
  justify-content: center;
  margin-bottom: 10px;
}
.icon-logo {
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
}
.line47 {
  left: 333px;
  top: 16px;

  border-left: solid 1px #a5a5a5;
  margin-bottom: -1px;

  height: 54px;
}
.text {
  display: flex;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
  padding: 0px 15px 0px 15px;
}
.textBlock {
  color: #a5a5a5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
}
.textBlock2 {
  margin-top: 0;
}
.labelWrapper {
  font-size: 16px;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  flex-shrink: 1;
}
.label {
  color: #232526;
  font-size: 16px;
  line-height: 24px;
  font-weight: unset !important;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
}
.line48 {
  left: 333px;
  top: 16px;

  border-left: solid 1px #a5a5a5;
  margin-bottom: -1px;

  height: 54px;
}
.text2 {
  display: flex;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
  padding: 0px 15px 0px 15px;
}
.textBlock3 {
  color: #a5a5a5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
}
.textBlock4 {
  margin-top: 0;
}
.labelWrapper2 {
  font-size: 16px;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  flex-shrink: 1;
}
.label2 {
  color: #232526;
  font-size: 16px;
  line-height: 24px;
  font-weight: unset !important;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
}
.line49 {
  left: 333px;
  top: 16px;

  border-left: solid 1px #a5a5a5;
  margin-bottom: -1px;

  height: 54px;
}
.text3 {
  display: flex;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
  padding: 0px 15px 0px 15px;
}
.textBlock5 {
  color: #a5a5a5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
}
.textBlock6 {
  margin-top: 0;
}
.labelWrapper3 {
  font-size: 16px;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  flex-shrink: 1;
}
.label3 {
  color: #232526;
  font-size: 16px;
  line-height: 24px;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
}
.line50 {
  left: 333px;
  top: 16px;

  border-left: solid 1px #a5a5a5;
  margin-bottom: -1px;

  height: 54px;
}
.text4 {
  display: flex;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
  padding: 0px 15px 0px 15px;
}
.textBlock7 {
  color: #a5a5a5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
}
.textBlock8 {
  margin-top: 0;
}
.labelWrapper4 {
  display: flex;
  font-size: 16px;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  flex-shrink: 1;
  justify-content: center;
}
.label4 {
  color: #232526;
  font-size: 16px;
  line-height: 24px;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
}
.frame39 {
  display: flex;

  gap: 19px;
  margin-left: auto;
}
.frame38 {
  position: relative;
  width: 101px;
  height: 46px;
  align-items: flex-start;
  border-radius: 8px;
  background-color: #0643f2;
  overflow: hidden;
}
.frame33 {
  display: flex;
  position: absolute;
  left: 50%;
  top: 11.5px;
  width: 63px;
  height: min-content;
  flex: 1;
  place-content: center;
  transform: translateX(-50%);
}
.fixit {
  display: flex;
  color: #fbfdff;
  font-size: 17px;
  line-height: 21px;
  font-weight: 600;
  font-family:
    Barlow,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
  justify-content: center;
}
.frame4143 {
  overflow: visible;
}
.cross-btn {
  width: 100%;
  height: 100%;
  background: white !important;
  color: red !important;
  font-size: large !important;
  border-color: #a5a5a5 !important;
}
.fixit-btn {
  display: flex;
  width: 100%;
  background: #0643f2 !important;
  height: 100%;
  justify-content: center;
}
